/** @jsx jsx */
import { jsx } from "@emotion/react";
import LogoEducandario from '../assets/images/lp/logotipo-educandario-footer.svg'
import {
  footer1,
  copyright,
} from "../assets/styles/Footer.styles";

const Footer = () => {
  return (
    <footer css={footer1}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 text-center">
            <div css={copyright}>
              <div><img src={LogoEducandario} alt='logo da Escola Vila Boa' /></div>
              <p>Rua T-30 Nº 2338, St. Bueno CEP: 74.210-060 | Goiânia - <br></br> GO 62 3285-3103</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
