import logoLight from "@/images/logo.png"
import logoDark from "@/images/logo2.png"
import sliderOne01 from "@/images/slider/1_1.jpg"
import sliderOne02 from "@/images/slider/1_2.jpg"
import aboutTwo01 from "@/images/home_1/5.jpg"
import aboutTwo02 from "@/images/home_1/5.jpg"

export const LogoImage = {
  light: logoLight,
  dark: logoDark,
};

export const NavLinks = [
  {
    name: "Home",
    url: "/",
    subItems: [
      {
        name: "Home 01",
        url: "/",
      },
      {
        name: "Home 02",
        url: "/index-2",
      },
      {
        name: "Home 03",
        url: "/index-3",
      },
    ],
  },
  {
    name: "Lorem",
    url: "/about",
  },
  {
    name: "Lorem",
    url: "/service",
    subItems: [
      {
        name: "Lorem",
        url: "/service",
      },
      {
        name: "Lorem ipsum",
        url: "/service-details",
      },
    ],
  },
  {
    name: "Lorem",
    url: "/portfolio",
    subItems: [
      {
        name: "Lorem 01",
        url: "/portfolio",
      },
      {
        name: "Lorem Ipsum",
        url: "/portfolio-details",
      },
    ],
  },
  {
    name: "Lorem",
    url: "/blog",
    subItems: [
      {
        name: "Lorem 01",
        url: "/blog",
      },
      {
        name: "Lorem 02",
        url: "/blog-2",
      },
      {
        name: "Lorem 03",
        url: "/blog-3",
      },
      {
        name: "Lorem Ipsum",
        url: "/blog-single",
      },
    ],
  },
  {
    name: "Lorem",
    url: "/contact",
  },
];

export const SliderOneData = [
  {
    image: sliderOne01,
    subTitle: "Lorem ipsum dolor sit amet",
    title: "LOREM IPSUM \n DOLOR SIT.",
    button: {
      label: "LOREM IPSUM",
      url: "/about",
    },
  },
  {
    image: sliderOne02,
    subTitle: "Lorem ipsum dolor sit amet",
    title: "LOREM IPSUM \n DOLOR SIT.",
    button: {
      label: "LOREM IPSUM",
      url: "/about",
    },
  },
];




export const AboutTwoData = {
  sectionContent: {
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing",
    subTitle: "Lorem ipsum dolor sit amet.",
    text:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet \n libero in augue volutpat varius. Lorem Ipsum is simply dummy text of the printing and typesetting industry has been the industry's standard dummy text ever since the 1500s.",
  },
  button: {
    label: "Lorem ipsum",
    url: "#",
  },
  gallery: [aboutTwo01, aboutTwo02],
};

