/** @jsx jsx */
import { css } from '@emotion/react'

export const header = css`
  background: #155DA9 no-repeat center center / cover;
  a {
    width: 261px;
    height: 52px;
    background: #FFD500;
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #155DA9;
    padding: 16px 56px;
    :hover {
      text-decoration: none;
      background: #CF1C60;
      color: #FFFFFF;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    
    img {
      max-width: 100%;
      width: auto;
    }
    a {
      margin-bottom:20px;
      display: flex;
      align-items: center;
      width: 60%;
      text-align: center;
      justify-content: center;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    
    img {
      max-width: 100%;
      width: auto;
    }
    a {
      margin-bottom:20px;
      display: flex;
      align-items: center;
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    img {
      max-width: 100%;
      width: auto;
    }
    a {
      margin-bottom:20px;
      display: flex;
      align-items: center;
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }
`
export const logoImg =css`
  margin-top: 42px;
  @media (min-width: 320px) and (max-width: 767px) {
    text-align: center;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    text-align: center;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    text-align: center;
  }
`

export const titleHeader = css`
  
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 100%;
  color: #ffffff;
  width: 100%;
  max-width: 550px;
  margin-top: 32px;
  margin-bottom: 12px;
  span {
    color: #FBBA00;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    line-height: 100%;
    margin-top: 0;
    font-size: 52px;
    text-align: left;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    font-size: 52px;
    text-align: center;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    font-size: 52px;
    text-align: center;
  }
  @media (min-width: 800px) and (max-width: 1900px) {
    position: relative;
    top: 50px;
    font-size: 52px;
  }
`

export const contentHeader = css`
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: #ffffff;
  width: 100%;
  max-width: 380px;
  margin-bottom: 40px;
  @media (min-width: 320px) and (max-width: 767px) {
    margin-top: 16px;
    text-align: left;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    margin-top: 16px;
    text-align: center;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    margin-top: 16px;
    text-align: center;
  }
  @media (min-width: 800px) and (max-width: 1900px) {
    position: relative;
    top: 90px;
  }
`

export const btnSaibaMais = css`
  @media (min-width: 800px) and (max-width: 1900px) {
    position: relative;
    top: 90px;
  }
`

export const EducandarioContent = css`
  padding: 60px 0px;
  background: #FFFFFF no-repeat center center / cover;
  @media (min-width: 320px) and (max-width: 767px) {
    img {
      max-width: 100%;
      width: auto;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    img {
      max-width: 100%;
      width: auto;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    img {
      max-width: 100%;
      width: auto;
    }
  }
  @media (min-width: 991px) and (max-width: 1199px) {
    hr {
      display: none;
    }
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    hr {
      display: none;
    }
  }
`

export const titleContent = css`
  font-weight: 700;
  font-size: 40px;
  line-height: 100%;
  color: #155DA9;
  margin-bottom: 32px;
  width: 100%;
  max-width: 405px;
  @media (min-width: 320px) and (max-width: 767px) {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`
export const CardSection = css`

display: flex;
flex-wrap: wrap;
gap: 10px;
margin-bottom: 10px;
justify-content: center;
@media (max-width: 789px) {
  flex-direction: column;
  justify-content: center;
}

`
export const subtitleContent = css`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #8B8A8A;
  width: 100%;
  max-width: 405px;
`

export const boxContent = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  ul {
    padding-left: 0px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #535353;
    margin-bottom: 0px;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    text-align: left !important;
    flex-wrap: wrap;
    align-content: center;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    text-align: left !important;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    text-align: left !important;
  }
` 

export const formContent = css`
  background-color: #155DA9;
  @media (min-width: 320px) and (max-width: 767px) {
    background-color: #FFD500;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    background-color: #FFD500;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    background-color: #FFD500;
  }
`

export const boxForm = css`
  background: #FFD500;
  border-radius: 32px;
  height: 100%;
  padding: 50px 12px;
  a {
    width: 261px;
    height: 52px;
    background: #431F43;
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 16px 56px;
    :hover {
      text-decoration: none;
      background: #CF1C60;
      color: #FFFFFF;
    }
  }
`

export const titleForm = css`
  font-weight: 700;
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  color: #003770;
  margin-bottom: 48px;
`

export const subtitleForm = css`
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  color: #003770;
  text-align: center;
`
export const formApi = css`
  text-align: center;
  input {
    background: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    max-width: 430px;
    padding: 12px;
    color: #DB802C;
    border: none;
  }
`
export const btnQueroParticipar = css`
  width: 261px;
  height: 52px;
  background: #155DA9;
  border-radius: 8px;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #FFFFFF;
  border: none;
  /* padding: 16px 56px; */
  margin-top: 24px;
  :hover {
    text-decoration: none;
    background: #CF1C60;
    color: #FFFFFF;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    width: 100%;
  }
`
export const DClouds = css`
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: inline;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: inline;
  }
`

export const MClouds = css`
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`

export const DDesktop = css` 
  margin-top: 60px;
  position: relative;
    right: 100px;
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: inline;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    display: inline;
  }

  .formImage {
    text-align: right;
    margin-left: 100px;
    @media (max-width: 500px) {
      margin-left:0;
    }
  }
`

export const DMobile = css` 
  @media (min-width: 320px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    display: flex;
  }

  @media (min-width: 991px) and (max-width: 1199px) {
    display: none;
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
    display: none;
  }
`
export const sucessContent = css`
  background: #155DA9;
  h2 {
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    text-align: center;
    color: #FFFFFF;
    width: 100%;
    max-width: 600px;
    text-align: center;
    margin: auto;
  }
  a {
    width: 261px;
    height: 52px;
    background: #FFD500;
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #155DA9;
    padding: 16px 56px;
    margin: auto;
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
      text-decoration: none;
      background: #CF1C60;
      color: #FFFFFF;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    height: 650px;
    img {
      max-width: 100%;
      width: auto;
    }
    a {
      display: flex;
      align-items: center;
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    height: 650px;
    img {
      max-width: 100%;
      width: auto;
    }
    a {
      display: flex;
      align-items: center;
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }

  @media (min-width: 768px) and (max-width: 990px) {
    height: 650px;
    img {
      max-width: 100%;
      width: auto;
    }
    a {
      display: flex;
      align-items: center;
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }
`